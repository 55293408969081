.Tiles__main-tile-area {
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.Tiles__inner-tile-area {
  perspective: 2000px;
}

.Tiles__tile-row {
  display: flex;
}
