.ConfigurationPage__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #47e291;
}

.ConfigurationPage__heading {
  font-weight: 300;
  font-size: 4vw;
  margin: 0 0 2vw;
}

.ConfigurationPage__message {
  font-size: 2vw;
  margin: 0 0 4vw;
}

.ConfigurationPage__screenname {
  background-color: #47e291;
  color: #000;
  font-size: 2.5vw;
  padding: 0 4px;
  margin-left: 8px;
}

.ConfigurationPage__admin-link {
  font-size: 1.25vw;
  margin: 0;
}

.ConfigurationPage__admin-link a {
  color: #47e291;
}
