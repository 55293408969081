.MessagePage__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.MessagePage__container h1,
.MessagePage__container p {
  margin: 0;
}

.MessagePage__container h1 {
  font-size: 4vw;
}

.MessagePage__container p {
  font-size: 2vw;
}

.MessagePage__spinner-container {
  width: 22vw;
  height: 22vw;
  margin: 2vw 0;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MessagePage__spinner-node {
  position: absolute;
  width: 20vw;
  height: 20vw;
  border-radius: 50%;
  background-color: transparent;
  border: 1vw solid transparent;
  border-top-color: #333;
  animation: 1.1s spin linear infinite;
}

.MessagePage__spinner-node:nth-child(2) {
  border-top-color: transparent;
  border-right-color: #47e291;
  animation: 1.4s spin linear infinite;
}
