.FlipTile__tile {
  transform-style: preserve-3d;
}

.FlipTile__tile--rotate {
  transition: transform 3.2s cubic-bezier(0.75, -0.5, 0.15, 1.11);
  will-change: transform;
}

.FlipTile__tile--red {
  background-color: #f82e40;
  color: white;
}

.FlipTile__tile--green {
  background-color: #47e291;
  color: black;
}

.FlipTile__tile--orange {
  background-color: #fc8519;
  color: white;
}

.FlipTile__tile-content {
  padding: 5%;
  height: 100%;

  box-sizing: border-box;
  transform-style: preserve-3d;
}

.FlipTile__tile-content-relative {
  height: 100%;
  width: 100%;
  position: relative;
  transform-style: preserve-3d;
  /* Workaround for flicker on Safari */
  -webkit-transform: translateZ(-1px);
}

.FlipTile__tile-front,
.FlipTile__tile-back {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  backface-visibility: hidden;
  /*
  Workaround to get 'backface-visiblity: hidden' on Firefox
  https://bugzilla.mozilla.org/show_bug.cgi?id=1201471
  */
  transform: rotateY(0deg);

  justify-content: space-between;

  /* Workaround for flicker on Safari */
  -webkit-transform: translateZ(2px);
}

.FlipTile__tile-back {
  transform: rotateY(180deg);
}

.FlipTile__tile--flipped {
  transform: rotateY(180deg);
}

.FlipTile__tile-arrow-container {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.FlipTile__tile-direction {
  width: 60%;
}

.FlipTile__tile-arrow--white > g {
  stroke: white;
}

.FlipTile__tile-timeDuration {
  margin-left: 8%;
}

.FlipTile__ellipsis {
  overflow: hidden;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.FlipTile__ellipsis-one-row {
  max-height: 1.2em;
  -webkit-line-clamp: 1;
}

.FlipTile__ellipsis-two-rows {
  max-height: 2.4em;
  -webkit-line-clamp: 2;
}
