.Footer__content-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.Footer__company-image {
  max-height: 50%;
}

.Footer__evoko-image {
  max-height: 20%;
}
