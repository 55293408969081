@font-face {
  font-family: 'Aktiv Grotesk';
  font-weight: 300;
  src: url('./assets/aktivgrotesklight.woff2') format('woff2'),
    url('./assets/aktivgrotesklight.woff') format('woff');
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  background: #000;
  color: #d8d8d8;
  font-family: 'Aktiv Grotesk', sans-serif;
  line-height: 1.2;
}
