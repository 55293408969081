.LeftSidebar__full-height-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.LeftSidebar__message {
  flex: 1;
  overflow: hidden;
  position: relative;
}

/* Fade vertical overflow of text */
.LeftSidebar__message:after {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  bottom: 0;
  content: '';
  height: 1em;
  left: 0;
  position: absolute;
  width: 100%;
}
